import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'

import FormField from '../FormField'
import './style.scss';

export default function ContactForm(props) {

  const { status } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const successMsg = 'Your message was successfully sent. Thank You for contacting us!';
  const errorMsg = 'Oops message not sent!';

  useEffect(() => {
    if(status === 'success') {
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
    }
  }, [status])

  return (
    <Form
      onSubmit={(event) => { 
        event.preventDefault();
        props.handleSubmit({name, email, phone, message})
      }}
      className="contact-form"
    >
      <FormField 
        id="name" 
        label="Your Name*" 
        value={name}
        fontColor="white"
        control={{
          required: true, 
          onChange: (event) => setName(event.target.value)
        }}
        className="form-group"
      />
      <FormField 
        id="email"
        label="Your Email*"
        value={email}
        fontColor="white"
        control={{
          required: true,
          type: 'email',
          onChange: (event) => setEmail(event.target.value)
        }}
        className="form-group" 
      />
      <FormField 
        id="phone" 
        label="Your Phone*" 
        value={phone}
        fontColor="white"
        control={{
          required: true,
          onChange: (event) => setPhone(event.target.value)
        }}
        className="form-group"
      />
      <FormField 
        id="message"
        label="Your Message"
        value={message}
        fontColor="white"
        control={{
          as: 'textarea', 
          rows: 5,
          onChange: (event) => setMessage(event.target.value)
        }}
        className="form-group"
      />
      {(props.messageSent && <p className='server-response'>{successMsg}</p>) || (props.messageError && <p className='server-response'>{errorMsg}</p>)}
      <Button variant="info" type="submit" >
        Get In Touch {status === "panding" && <Spinner size="sm" animation="border" variant="success" /> }
      </Button>
    </Form>
  );
}