import React from 'react';
import { Form } from 'react-bootstrap';

export default function ContactFormField(props) {
  const { control, id, label, value, fontColor, className } = props
  return (
      <Form.Group controlId={id} className={className}>
        <Form.Label>{label}</Form.Label>
        <Form.Control  className={`font-${fontColor}`} value={value} {...control}/>
      </Form.Group>
  )
}