import React, { useEffect } from 'react'

import './style.scss'

const OpenPositions = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.rippling-ats.com/javascripts/embed.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [])

  return (
    <div id="hiringthing-jobs"></div>
    
  )
}

export default OpenPositions;